import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../../styles/index.css";

import image1 from "../../../assets/image/drywall/1_drywall.webp";
import image2 from "../../../assets/image/drywall/2_drywall.webp";
import image4 from "../../../assets/image/drywall/4_drywall.webp";
import image5 from "../../../assets/image/drywall/5_drywall.webp";
import image6 from "../../../assets/image/drywall/6_drywall.webp";
import image7 from "../../../assets/image/drywall/7_drywall.webp";
import image8 from "../../../assets/image/drywall/8_drywall.webp";
import image9 from "../../../assets/image/drywall/9_drywall.webp";

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} slick-prev-new`}
      style={{ ...style, left: "1px" }}
      onClick={onClick}
    />
  );
};

const NextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} slick-next-new`}
      style={{ ...style, right: "59px" }}
      onClick={onClick}
    />
  );
};

const ImageSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const images = [
    image1,
    image2,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
  ];

  return (
    <div className="image-slider">
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index}>
            <img src={image} alt={`Slide ${index + 1}`} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ImageSlider;
