import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import "./styles/index.css";

import Header from "./components/pages/Header";
import Home from "./components/pages/Home";
import About from "./components/pages/About";
import TvMounting from "./components/pages/TvMounting";
import FurnitureAssembly from "./components/pages/FurnitureAssembly";
import Contact from "./components/pages/Contact";
import FurnitureAssemblyTools from "./components/articles/FurnitureAssemblyTools";
import Footer from "./components/pages/Footer";
import PrivacyPolicy from "./components/policies/PrivacyPolicy";
import TermsOfService from "./components/policies/TermsofService";
import SafetySupport from "./components/policies/SafetySupport";
import ScrollToTop from "./components/hooks/ScrollToTop";
import CookiePolicy from "./components/policies/CookiePolicy";
import Handyman from "./components/pages/Handyman";
import DrywallRepair from "./components/pages/city/DrywallRepairService";
import Painters from "./components/pages/Painters";

import Blog from "./components/pages/Blog";
import ArticlePage from "./components/articles/ArticlePage";

import FurnitureAssemblyArticles from "./components/articles/FurnitureAssemblyArticles";
import BedAssemblyService from "./components/articles/BedAssemblyService";
import FurnitureInstallationService from "./components/articles/FurnitureInstallationService";
import TvMountingService from "./components/articles/TvMountingService";
import FlatPackServices from "./components/articles/FlatPackServices";
import DeskAssemblyService from "./components/articles/DeskAssemblyService";
import MurphyBedInstallationService from "./components/articles/MurphyBedInstallationService";
import HandymanFurnitureAssembly from "./components/articles/HandymanFurnitureAssembly";
import FurnitureDisassemblyService from "./components/articles/FurnitureDisassemblyService";
import OfficeFurnitureInstallation from "./components/articles/OfficeFurnitureInstallation";
import FlatPackService from "./components/articles/FlatPackService";
import HandymanServiceNearMe from "./components/articles/HandymanServiceNearMe";
import ArticlesPaintersServiceNearMe from "./components/articles/ArticlesPaintersServiceNearMe";
import DeckRepairNearMe from "./components/articles/DeckRepairNearMe";
import HandymanOrangeCounty from "./components/articles/HandymanOrangeCounty";
import AffordablePaintersNearMe from "./components/articles/AffordablePaintersNearMe";
import RepairDecksNearMe from "./components/articles/RepairDecksNearMe";
import IkeaHandymanNearMeNearMe from "./components/articles/IkeaHandymanNearMeNearMe";
import BestFurnitureAssemblyService from "./components/articles/BestFurnitureAssemblyService";
import OfficeFurnitureAssemblyServices from "./components/articles/OfficeFurnitureAssemblyServices";
import LocalFurnitureAssemblyService from "./components/articles/LocalFurnitureAssemblyService";
import SameDayFurnitureAssembly from "./components/articles/SameDayFurnitureAssembly";
import PatioFurnitureAssembly from "./components/articles/PatioFurnitureAssembly";
import MurphyBedAssemblyService from "./components/articles/MurphyBedAssemblyService";
import HandymanAssemblyNearMe from "./components/articles/HandymanAssemblyNearMe";
import BestHandymanServiceNearMe from "./components/articles/BestHandymanServiceNearMe";
import LocalHandymanServices from "./components/articles/LocalHandymanServices";
import HandymanIrvine from "./components/articles/HandymanIrvine";
import HandymanLagunaBeach from "./components/articles/HandymanLagunaBeach";
import HandymanHuntingtonBeach from "./components/articles/HandymanHuntingtonBeach";
import HandymanSanClemente from "./components/articles/HandymanSanClemente";
import HandymanMissionViejo from "./components/articles/HandymanMissionViejo";
import HandymanLakeForest from "./components/articles/HandymanLakeForest";
import HandymanNewportBeach from "./components/articles/HandymanNewportBeach";

import HandymanServiceAnaheim from "./components/pages/city/HandymanServiceAnaheim";
import HandymanServiceAlisoViejo from "./components/pages/city/HandymanServiceAlisoViejo";
import HandymanServiceBrea from "./components/pages/city/HandymanServiceBrea";
import HandymanServiceBuenaPark from "./components/pages/city/HandymanServiceBuenaPark";
import HandymanServiceCostaMesa from "./components/pages/city/HandymanServiceCostaMesa";
import HandymanServiceCotoDeCaza from "./components/pages/city/HandymanServiceCotoDeCaza";
import HandymanServiceCowanHeights from "./components/pages/city/HandymanServiceCowanHeights";
import HandymanServiceCypress from "./components/pages/city/HandymanServiceCypress";
import HandymanServiceDanaPoint from "./components/pages/city/HandymanServiceDanaPoint";
// import HandymanServiceEmeraldBay from "./components/pages/city/HandymanServiceEmeraldBay";
// import HandymanServiceFountainValley from "./components/pages/city/HandymanServiceFountainValley";
// import HandymanServiceFullerton from "./components/pages/city/HandymanServiceFullerton";
// import HandymanServiceGardenGrove from "./components/pages/city/HandymanServiceGardenGrove";
import HandymanServiceHuntingtonBeach from "./components/pages/city/HandymanServiceHuntingtonBeach";
import HandymanServiceIrvine from "./components/pages/city/HandymanServiceIrvine";
// import HandymanServiceLaHabra from "./components/pages/city/HandymanServiceLaHabra";
// import HandymanServiceLaPalma from "./components/pages/city/HandymanServiceLaPalma";
import HandymanServiceLaderaRanch from "./components/pages/city/HandymanServiceLaderaRanch";
import HandymanServiceLagunaBeach from "./components/pages/city/HandymanServiceLagunaBeach";
import HandymanServiceLagunaHills from "./components/pages/city/HandymanServiceLagunaHills";
import HandymanServiceLagunaNiguel from "./components/pages/city/HandymanServiceLagunaNiguel";
// import HandymanServiceLagunaWoods from "./components/pages/city/HandymanServiceLagunaWoods";
import HandymanServiceLakeForest from "./components/pages/city/HandymanServiceLakeForest";
// import HandymanServiceLemonHeights from "./components/pages/city/HandymanServiceLemonHeights";
// import HandymanServiceLosAlamitos from "./components/pages/city/HandymanServiceLosAlamitos";
// import HandymanServiceMidwayCity from "./components/pages/city/HandymanServiceMidwayCity";
import HandymanServiceMissionViejo from "./components/pages/city/HandymanServiceMissionViejo";
// import HandymanServiceModjeskaCanyon from "./components/pages/city/HandymanServiceModjeskaCanyon";
import HandymanServiceNewportBeach from "./components/pages/city/HandymanServiceNewportBeach";
// import HandymanServiceOrange from "./components/pages/city/HandymanServiceOrange";
// import HandymanServicePlacentia from "./components/pages/city/HandymanServicePlacentia";
import HandymanServiceRanchoMissionViejo from "./components/pages/city/HandymanServiceRanchoMissionViejo";
import HandymanServiceRanchoSantaMargarita from "./components/pages/city/HandymanServiceRanchoSantaMargarita";
// import HandymanServiceRedHill from "./components/pages/city/HandymanServiceRedHill";
// import HandymanServiceRossmoor from "./components/pages/city/HandymanServiceRossmoor";
import HandymanServiceSanClemente from "./components/pages/city/HandymanServiceSanClemente";
// import HandymanServiceSanJuanCapistrano from "./components/pages/city/HandymanServiceSanJuanCapistrano";
// import HandymanServiceSealBeach from "./components/pages/city/HandymanServiceSealBeach";
// import HandymanServiceSilveradoCanyon from "./components/pages/city/HandymanServiceSilveradoCanyon";
// import HandymanServiceStanton from "./components/pages/city/HandymanServiceStanton";
// import HandymanServiceTrabucoCanyon from "./components/pages/city/HandymanServiceTrabucoCanyon";
// import HandymanServiceTustin from "./components/pages/city/HandymanServiceTustin";
// import HandymanServiceVillaPark from "./components/pages/city/HandymanServiceVillaPark";
// import HandymanServiceWestminster from "./components/pages/city/HandymanServiceWestminster";
// import HandymanServiceYorbaLinda from "./components/pages/city/HandymanServiceYorbaLinda";

function App() {
  return (
    <div className="App">
      <Header />
      <ScrollToTop />
      <Routes>
        <Route path="*" element={<Navigate to="/handyman_services" />} />

        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/tv_mounting" element={<TvMounting />} />
        <Route path="/furniture_assembly" element={<FurnitureAssembly />} />
        <Route path="/handyman_services" element={<Handyman />} />
        <Route path="/drywall_repair_near_me" element={<DrywallRepair />} />
        <Route path="/painters-near-me" element={<Painters />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/safety-support" element={<SafetySupport />} />
        <Route path="/cookie-policy" element={<CookiePolicy />} />

        <Route path="/:service" element={<FurnitureAssembly />} />
        <Route path="/articles/:slug" element={<ArticlePage />} />

        <Route
          path="/furniture-assembly-tools-for-handyman"
          element={<FurnitureAssemblyTools />}
        />
        <Route
          path="/articles/furniture-assembly-service"
          element={<FurnitureAssemblyArticles />}
        />
        <Route
          path="/articles/bed-assembly-service"
          element={<BedAssemblyService />}
        />
        <Route
          path="/articles/furniture-installation-service"
          element={<FurnitureInstallationService />}
        />
        <Route
          path="/articles/tv-mounting-service"
          element={<TvMountingService />}
        />
        <Route
          path="/articles/flat-pack-services"
          element={<FlatPackServices />}
        />
        <Route
          path="/articles/desk-assembly-service"
          element={<DeskAssemblyService />}
        />
        <Route
          path="/articles/murphy-bed-installation-service"
          element={<MurphyBedInstallationService />}
        />
        <Route
          path="/articles/handyman-furniture-assembly"
          element={<HandymanFurnitureAssembly />}
        />
        <Route
          path="/articles/furniture-disassembly-service"
          element={<FurnitureDisassemblyService />}
        />
        <Route
          path="/articles/office-furniture-installation"
          element={<OfficeFurnitureInstallation />}
        />
        <Route
          path="/articles/flat-pack-service"
          element={<FlatPackService />}
        />
        <Route
          path="/articles/handyman-service-near-me"
          element={<HandymanServiceNearMe />}
        />
        <Route
          path="/articles/painters-service-near-me"
          element={<ArticlesPaintersServiceNearMe />}
        />
        <Route
          path="/articles/deck-repair-near-me"
          element={<DeckRepairNearMe />}
        />
        <Route
          path="/articles/handyman-orange-county"
          element={<HandymanOrangeCounty />}
        />
        <Route
          path="/articles/painting-services-near-me"
          element={<AffordablePaintersNearMe />}
        />
        <Route
          path="/articles/repair-decks-near-me"
          element={<RepairDecksNearMe />}
        />
        <Route
          path="/articles/ikea-handyman-near-me"
          element={<IkeaHandymanNearMeNearMe />}
        />
        <Route
          path="/articles/best-furniture-assembly-service"
          element={<BestFurnitureAssemblyService />}
        />
        <Route
          path="/articles/office-furniture-assembly-service"
          element={<OfficeFurnitureAssemblyServices />}
        />
        <Route
          path="/articles/local-furniture-assembly-service"
          element={<LocalFurnitureAssemblyService />}
        />
        <Route
          path="/articles/same-day-furniture-assembly"
          element={<SameDayFurnitureAssembly />}
        />
        <Route
          path="/articles/patio-furniture-assembly"
          element={<PatioFurnitureAssembly />}
        />
        <Route
          path="/articles/murphy-bed-assembly-service"
          element={<MurphyBedAssemblyService />}
        />
        <Route
          path="/articles/handyman-assembly-near-me"
          element={<HandymanAssemblyNearMe />}
        />
        <Route
          path="/articles/best-handyman-service-near-me"
          element={<BestHandymanServiceNearMe />}
        />
        <Route
          path="/articles/local-handyman-services"
          element={<LocalHandymanServices />}
        />
        <Route path="/articles/handyman-irvine" element={<HandymanIrvine />} />
        <Route
          path="/articles/handyman-laguna-beach"
          element={<HandymanLagunaBeach />}
        />
        <Route
          path="/articles/handyman-huntington-beach"
          element={<HandymanHuntingtonBeach />}
        />
        <Route
          path="/articles/handyman-san-clemente"
          element={<HandymanSanClemente />}
        />
        <Route
          path="/articles/handyman-mission-viejo"
          element={<HandymanMissionViejo />}
        />
        <Route
          path="/articles/handyman-lake-forest"
          element={<HandymanLakeForest />}
        />
        <Route
          path="/articles/handyman-newport-beach"
          element={<HandymanNewportBeach />}
        />

        {/*Handyman page by city*/}
        <Route
          path="/anaheim/handyman_services"
          element={<HandymanServiceAnaheim />}
        />
        <Route
          path="/aliso_viejo/handyman_services"
          element={<HandymanServiceAlisoViejo />}
        />
        <Route
          path="/brea/handyman_services"
          element={<HandymanServiceBrea />}
        />
        <Route
          path="/buena_park/handyman_services"
          element={<HandymanServiceBuenaPark />}
        />
        <Route
          path="/costa_mesa/handyman_services"
          element={<HandymanServiceCostaMesa />}
        />
        <Route
          path="/coto_de_caza/handyman_services"
          element={<HandymanServiceCotoDeCaza />}
        />
        <Route
          path="/cowan_heights/handyman_services"
          element={<HandymanServiceCowanHeights />}
        />
        <Route
          path="/cypress/handyman_services"
          element={<HandymanServiceCypress />}
        />
        <Route
          path="/dana_point/handyman_services"
          element={<HandymanServiceDanaPoint />}
        />
        {/*<Route*/}
        {/*  path="/emerald_bay/handyman_services"*/}
        {/*  element={<HandymanServiceEmeraldBay />}*/}
        {/*/>*/}
        {/*<Route*/}
        {/*  path="/fountain_valley/handyman_services"*/}
        {/*  element={<HandymanServiceFountainValley />}*/}
        {/*/>*/}
        {/*<Route*/}
        {/*  path="/fullerton/handyman_services"*/}
        {/*  element={<HandymanServiceFullerton />}*/}
        {/*/>*/}
        {/*<Route*/}
        {/*  path="/garden_grove/handyman_services"*/}
        {/*  element={<HandymanServiceGardenGrove />}*/}
        {/*/>*/}
        <Route
          path="/huntington_beach/handyman_services"
          element={<HandymanServiceHuntingtonBeach />}
        />
        <Route
          path="/irvine/handyman_services"
          element={<HandymanServiceIrvine />}
        />
        {/*<Route*/}
        {/*  path="/la_habra/handyman_services"*/}
        {/*  element={<HandymanServiceLaHabra />}*/}
        {/*/>*/}
        {/*<Route*/}
        {/*  path="/la_palma/handyman_services"*/}
        {/*  element={<HandymanServiceLaPalma />}*/}
        {/*/>*/}
        <Route
          path="/ladera_ranch/handyman_services"
          element={<HandymanServiceLaderaRanch />}
        />
        <Route
          path="/laguna_beach/handyman_services"
          element={<HandymanServiceLagunaBeach />}
        />
        <Route
          path="/laguna_hills/handyman_services"
          element={<HandymanServiceLagunaHills />}
        />
        <Route
          path="/laguna_niguel/handyman_services"
          element={<HandymanServiceLagunaNiguel />}
        />
        {/*<Route*/}
        {/*  path="/laguna_woods/handyman_services"*/}
        {/*  element={<HandymanServiceLagunaWoods />}*/}
        {/*/>*/}
        <Route
          path="/lake_forest/handyman_services"
          element={<HandymanServiceLakeForest />}
        />
        {/*<Route*/}
        {/*  path="/lemon_heights/handyman_services"*/}
        {/*  element={<HandymanServiceLemonHeights />}*/}
        {/*/>*/}
        {/*<Route*/}
        {/*  path="/los_alamitos/handyman_services"*/}
        {/*  element={<HandymanServiceLosAlamitos />}*/}
        {/*/>*/}
        {/*<Route*/}
        {/*  path="/midway_city/handyman_services"*/}
        {/*  element={<HandymanServiceMidwayCity />}*/}
        {/*/>*/}
        <Route
          path="/mission_viejo/handyman_services"
          element={<HandymanServiceMissionViejo />}
        />
        {/*<Route*/}
        {/*  path="/modjeska_canyon/handyman_services"*/}
        {/*  element={<HandymanServiceModjeskaCanyon />}*/}
        {/*/>*/}
        <Route
          path="/newport_beach/handyman_services"
          element={<HandymanServiceNewportBeach />}
        />
        {/*<Route*/}
        {/*  path="/orange/handyman_services"*/}
        {/*  element={<HandymanServiceOrange />}*/}
        {/*/>*/}
        {/*<Route*/}
        {/*  path="/placentia/handyman_services"*/}
        {/*  element={<HandymanServicePlacentia />}*/}
        {/*/>*/}
        <Route
          path="/rancho_mission_viejo/handyman_services"
          element={<HandymanServiceRanchoMissionViejo />}
        />
        <Route
          path="/rancho_santa_margarita/handyman_services"
          element={<HandymanServiceRanchoSantaMargarita />}
        />
        {/*<Route*/}
        {/*  path="/red_hill/handyman_services"*/}
        {/*  element={<HandymanServiceRedHill />}*/}
        {/*/>*/}
        {/*<Route*/}
        {/*  path="/rossmoor/handyman_services"*/}
        {/*  element={<HandymanServiceRossmoor />}*/}
        {/*/>*/}
        <Route
          path="/san_clemente/handyman_services"
          element={<HandymanServiceSanClemente />}
        />
        {/*<Route*/}
        {/*  path="/san_juan_capistrano/handyman_services"*/}
        {/*  element={<HandymanServiceSanJuanCapistrano />}*/}
        {/*/>*/}
        {/*<Route*/}
        {/*  path="/seal_beach/handyman_services"*/}
        {/*  element={<HandymanServiceSealBeach />}*/}
        {/*/>*/}
        {/*<Route*/}
        {/*  path="/silverado_canyon/handyman_services"*/}
        {/*  element={<HandymanServiceSilveradoCanyon />}*/}
        {/*/>*/}
        {/*<Route*/}
        {/*  path="/stanton/handyman_services"*/}
        {/*  element={<HandymanServiceStanton />}*/}
        {/*/>*/}
        {/*<Route*/}
        {/*  path="/trabuco_canyon/handyman_services"*/}
        {/*  element={<HandymanServiceTrabucoCanyon />}*/}
        {/*/>*/}
        {/*<Route*/}
        {/*  path="/tustin/handyman_services"*/}
        {/*  element={<HandymanServiceTustin />}*/}
        {/*/>*/}
        {/*<Route*/}
        {/*  path="/villa_park/handyman_services"*/}
        {/*  element={<HandymanServiceVillaPark />}*/}
        {/*/>*/}
        {/*<Route*/}
        {/*  path="/westminster/handyman_services"*/}
        {/*  element={<HandymanServiceWestminster />}*/}
        {/*/>*/}
        {/*<Route*/}
        {/*  path="/yorba_linda/handyman_services"*/}
        {/*  element={<HandymanServiceYorbaLinda />}*/}
        {/*/>*/}
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
