import React, { useState } from "react";
import GetQuote from "../../quote/GetQuotePainting";
import Reviews from "../../reviews/ReviewsDrywallRepair";
import GetQuoteForm from "../../quote/GetQuotePainters";
import Modal from "react-modal";

import {
  FaSearch,
  FaShoppingCart,
  FaTools,
  FaRulerCombined,
  FaShapes,
  FaPaintRoller,
} from "react-icons/fa";

import ImageSliderPainter from "../../sliders/drywall/ImageSliderDrywall";
import ImageSliderPainter2 from "../../sliders/drywall/ImageSliderDrywall_2";
import ImageSliderPainter3 from "../../sliders/drywall/ImageSliderDrywall_3";
import ImageSliderPainter4 from "../../sliders/drywall/ImageSliderDrywall_4";
import ImageSliderPainter5 from "../../sliders/drywall/ImageSliderDrywall_5";

import GoogleGuaranteed from "../../../assets/image/google-guaranteed.png";

function DrywallRepairService() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedService, setSelectedService] = useState(null);

  const openModal = (serviceName) => {
    setSelectedService(serviceName);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedService(null);
    setModalIsOpen(false);
  };

  const handleSubmit = (formData) => {
    console.log("Form data submitted from GetQuoteForm:", formData);
    closeModal();
  };

  const steps = [
    {
      icon: <FaSearch />,
      title: "Initial Inspection",
      description:
        "A thorough assessment of the damage based on provided photos to determine the best repair approach.",
    },
    {
      icon: <FaShoppingCart />,
      title: "Material Selection & Purchase",
      description:
        "I personally select, purchase, and deliver high-quality materials from the store to ensure durability and seamless integration.",
    },
    {
      icon: <FaTools />,
      title: "Professional Wall Repair",
      description:
        "I expertly restore the drywall, ensuring a flawless and secure surface.",
    },
    {
      icon: <FaRulerCombined />,
      title: "Surface Smoothing & Priming",
      description:
        "The wall is meticulously prepared, sanded, and primed for optimal paint adhesion.",
    },
    {
      icon: <FaShapes />,
      title: "Custom Texture Matching",
      description:
        "I replicate the existing wall texture for a seamless, professional finish.",
    },
    {
      icon: <FaPaintRoller />,
      title: "Final Painting & Cleanup",
      description:
        "A precise paint application and a thorough cleanup leave your space spotless and refreshed.",
    },
  ];

  const city = "Orange County";

  return (
    <section className="features" id="tv_mounting_service">
      <div className="container">
        <div className="container_tv">
          <div className="mobile-container">
            <br></br>
            <br></br>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={GoogleGuaranteed}
                alt="Drywall Repair"
                style={{
                  width: "150px",
                  height: "150px",
                  objectFit: "cover",
                  marginRight: "20px",
                }}
              />
              <h2 className="about__h1">
                Professional Drywall Repair Service in{" "}
                <span style={{ color: "orange", fontWeight: "bold" }}>
                  {city}
                </span>{" "}
                - Trusted & Reliable
              </h2>
            </div>

            <br></br>

            <div className="handyman_header_desc">
              Hi, I'm Roman, and welcome to <strong>HubPro.US</strong> – your
              trusted choice for
              <strong> drywall repair near me</strong>! Whether you’re dealing
              with cracks, holes, or water damage, our family-owned business
              provides expert <strong>drywall repair</strong> and finishing. We
              ensure smooth, seamless results that restore your walls to
              perfection. Count on us for reliable service and exceptional
              craftsmanship. Let us make your home look its best!
            </div>
            <br></br>
          </div>

          <div className="mobile-container">
            <h2 className="about__h1">
              Best Drywall Repair Service in{" "}
              <span style={{ color: "orange", fontWeight: "bold" }}>
                {city}
              </span>
            </h2>
            <br />
            <ImageSliderPainter />
            <br></br>
            <br></br>
          </div>

          <div className="mobile-container">
            <div>
              <ul className="tv_mounting_skills_list">
                <p className="tv_mounting_skills">Precision Hole Patching</p>
                <p className="tv_mounting_skills">
                  Professional Wall Restoration
                </p>
                <p className="tv_mounting_skills">Expert Drywall Repair</p>
                <p className="tv_mounting_skills">Flawless Damage Fixing</p>
              </ul>
            </div>

            <div className="repair-steps-container">
              {/*<h2 className="repair-title">Professional Wall Repair Process</h2>*/}
              <div className="repair-grid">
                {steps.map((step, index) => (
                  <div key={index} className="repair-card">
                    <div className="repair-icon">{step.icon}</div>
                    <h3 className="repair-step-title">{step.title}</h3>
                    <p className="repair-description">{step.description}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div>
            <a
              className="get-quote__link action-button"
              id="bottomSmsButtonFurniturePage"
              onClick={() => openModal()}
            >
              Get a Free Estimate
            </a>
            <br></br>
          </div>

          <div className="mobile-container">
            <Reviews />
            <br></br>
            <br></br>
          </div>

          <div className="mobile-container">
            <h2 className="about__h1">Sheetrock Repair Near Me</h2>
            <br />
            <ImageSliderPainter2 />
            <br></br>
            <br></br>
          </div>

          <div>
            <a
              className="get-quote__link action-button"
              id="bottomSmsButtonFurniturePage"
              onClick={() => openModal()}
            >
              Get a Free Estimate
            </a>
            <br></br>
          </div>

          <div className="mobile-container">
            <h2 className="about__h1">
              Drywall Repair and Painting Near Me / Drywall Handyman Near Me
            </h2>
            <br />
            <ImageSliderPainter3 />
            <br></br>
            <br></br>
          </div>

          <br />
          <ImageSliderPainter4 />
          <br></br>
          <br></br>

          <br />
          <ImageSliderPainter5 />
          <br></br>
          <br></br>

          <div>
            <a
              className="get-quote__link action-button"
              id="bottomSmsButtonFurniturePage"
              onClick={() => openModal()}
            >
              Get a Free Estimate
            </a>
            <br></br>
          </div>

          <div className="container_tv_qoute_form">
            <br></br>
            <GetQuote />
            <br></br>
            <br></br>
          </div>

          <div className="mobile-container">
            <div className="text">
              <h3>Top-Tier Drywall Repair Services in {city}</h3>
              <p>
                Looking for expert <strong>drywall repair near me</strong> in{" "}
                {city}? Our team provides high-quality sheetrock repair services
                to restore your walls to perfect condition. Whether you need
                minor drywall fixes or extensive sheetrock repair, we deliver
                flawless results every time.
              </p>

              <h3>Precision and Attention to Detail in Drywall Repair</h3>
              <p>
                Our drywall repair service focuses on clean lines and smooth
                finishes. We ensure that every repair blends seamlessly with
                your existing walls, whether it’s a small crack or a large hole.
                Trust us to restore your walls with meticulous care.
              </p>

              <h3>Personalized Drywall Repair Solutions</h3>
              <p>
                No two drywall problems are the same, and we understand that.
                That’s why we offer tailored{" "}
                <strong>drywall repair services</strong> that meet your specific
                needs. From sheetrock hole repairs to full wall restorations, we
                ensure the best solution for your home.
              </p>

              <h3>Experience You Can Trust in Drywall Repairs</h3>
              <p>
                With years of experience in <strong>drywall repair near</strong>{" "}
                you, our team has a proven track record of customer
                satisfaction. We use industry-leading techniques and the best
                materials to provide long-lasting drywall repairs you can count
                on.
              </p>

              <h3>
                Enhance Your Home with{" "}
                <strong>Professional Drywall Repair</strong>
              </h3>
              <p>
                Drywall repairs not only improve the appearance of your home but
                also protect its structural integrity. Whether you need
                <strong>sheetrock patching</strong> or full drywall replacement,
                our services help keep your home looking its best.
              </p>

              <h3>Eco-Friendly Drywall Repair Practices</h3>
              <p>
                We care about your health and the environment. That’s why we use
                low-VOC materials and sustainable practices in every{" "}
                <strong>drywall repair job</strong>, ensuring a safe,
                eco-friendly experience for you and your family.
              </p>

              <h3>Support Your Local {city} Drywall Repair Business</h3>
              <p>
                As a family-owned{" "}
                <strong>drywall repair service in {city}</strong>, we’re proud
                to serve our local community. By choosing us, you support
                professionals who care about the quality of their work and the
                satisfaction of their neighbors.
              </p>
            </div>
          </div>

          <br></br>
          <div className="mobile-only">
            <a
              className="get-quote__link action-button"
              id="bottomSmsButtonTvPage"
              href="sms:949-438-0343"
            >
              Get a Free Estimate!
            </a>
          </div>
        </div>
      </div>

      <div className="get-quote">
        <div className="get-quote__container container">
          <div className="get-quote__p">
            Transform Your Home with Expert Drywall Repair Service {city}!
          </div>
          <a
            className="get-quote__link action-button"
            id="bottomSmsButtonTvPage"
            href="sms:+19494380343"
          >
            Text Us 24/7
          </a>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Get a Free Quote Modal"
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            top: "50%",
            background: "linear-gradient(to bottom, #0090ea, #0064cc)", // Исправлено здесь
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "20px",
            maxWidth: "600px", // Можно настроить ширину модального окна
            maxHeight: "1600px", // Можно настроить максимальную высоту модального окна
            overflowY: "auto", // Разрешить прокрутку содержимого, если оно не помещается
            borderRadius: "10px", // Закругление углов
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)", // Тень
            border: "none", // Убрать окантовку
          },
        }}
      >
        <h5 className="banner-quote__h1">Get a Free Estimate Today!</h5>
        <GetQuoteForm handleSubmit={handleSubmit} />
      </Modal>
    </section>
  );
}

export default DrywallRepairService;
