import React, { useState, useEffect } from "react";
import Logo from "../../assets/image/logo-handyman.webp";
import Navigation from "../menu/Navigation";
import BurgerMenu from "../menu/BurgerMenu"; // Import the new BurgerMenu component

function Header() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.matchMedia("(max-width: 1678px)").matches);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const phoneText = isMobile ? "(949) 438-0343" : "☎ Call Us: (949) 438-0343";
  const smsText = isMobile ? "Get a Quote Now" : "Text For Quick Reply";

  return (
    <header className="custom-header">
      <div className="custom-header__logo">
        <a href="/public" rel="noopener">
          <img
            src={Logo}
            alt="Orange County Furniture Assembly & TV Mounting Service"
          />
          <span className="logo-text_1">Hub</span>
          <span className="logo-text_2">Pro</span>
        </a>
      </div>
      {isMobile && <BurgerMenu />}{" "}
      {/* Add the BurgerMenu component for mobile */}
      <nav className="custom-header__menu">
        <Navigation />
      </nav>
      <div className="phone-container">
        <div className="button-container">
          <a
            href="tel:+19494380343"
            id="phoneButtonHeader"
            className="button phone-button"
          >
            {phoneText}
          </a>
          <a
            href="sms:+19494380343"
            id="smsButtonHeader"
            className="button sms-button"
          >
            {smsText}
          </a>
        </div>
      </div>
    </header>
  );
}

export default Header;
