import React from "react";
import Slider from "react-slick";

import Google_Reviews from "../../assets/image/google_g_icon.webp";
import Yelp_Reviews from "../../assets/image/yelp_logo.webp";
import DefaultYelpAvatar from "../../assets/image/default.png";
import YelpFavicon from "../../assets/image/yelp_favicon.png";
import GoogleFavicon from "../../assets/image/favicon_google.png";

import John from "../../assets/image/rewiews_painting/painting_rewiews_1.png";
import Anna from "../../assets/image/rewiews_painting/painting_rewiews_2.png";
import Mike from "../../assets/image/rewiews_painting/painting_rewiews_3.png";
import Sarah from "../../assets/image/rewiews_painting/painting_rewiews_4.png";
import Robert from "../../assets/image/rewiews_painting/painting_rewiews_5.png";
import Emma from "../../assets/image/rewiews_painting/painting_rewiews_6.png";
import Jason from "../../assets/image/rewiews_painting/painting_rewiews_7.png";
import Lisa from "../../assets/image/rewiews_painting/painting_rewiews_8.png";
import Mark from "../../assets/image/rewiews_painting/painting_rewiews_9.png";
import Laura from "../../assets/image/rewiews_painting/painting_rewiews_10.png";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Custom arrow components
const PrevArrow = ({ className, style, onClick }) => (
  <div
    className={`${className} slick-prev-tv`}
    style={style}
    onClick={onClick}
  />
);

const NextArrow = ({ className, style, onClick }) => (
  <div
    className={`${className} slick-next-tv`}
    style={style}
    onClick={onClick}
  />
);

// Reviews data
const reviews = [
  {
    platformIcon: Google_Reviews,
    avatar: John,
    name: "John R.",
    date: "January 5, 2025",
    stars: "★★★★★",
    review:
      "We had a large hole in our drywall after a plumbing repair. HubPro came in and patched it seamlessly. Roman did an amazing job—can’t even tell there was damage! Highly recommend.",
    link: "https://g.page/r/CYsIhjJfreWDEB0/",
  },
  {
    platformIcon: Yelp_Reviews,
    avatar: Anna,
    name: "Anna W.",
    date: "January 22, 2025",
    stars: "★★★★★",
    review:
      "Roman repaired multiple cracks in our living room drywall and repainted the whole space. The walls look brand new. Quick, clean, and professional! Would definitely hire again.",
    link: "https://www.yelp.com/biz/hubpro-furniture-assembly-irvine",
  },
  {
    platformIcon: Google_Reviews,
    avatar: Mike,
    name: "Mike Johnson",
    date: "February 4, 2025",
    stars: "★★★★★",
    review:
      "Fantastic work from HubPro! We had water damage on our drywall, and Roman not only fixed the damaged area but also matched the paint perfectly. Great attention to detail!",
    link: "https://g.page/r/CYsIhjJfreWDEB0/",
  },
  {
    platformIcon: Google_Reviews,
    avatar: Sarah,
    name: "Sarah P.",
    date: "February 12, 2025",
    stars: "★★★★★",
    review:
      "Roman did a great job patching up our drywall holes after electrical work. The repairs were invisible, and the walls look flawless. Really appreciate the quick turnaround!",
    link: "https://g.page/r/CYsIhjJfreWDEB0/",
  },
  {
    platformIcon: Yelp_Reviews,
    avatar: Robert,
    name: "Robert L.",
    date: "February 20, 2025",
    stars: "★★★★★",
    review:
      "We had several dents and cracks in our drywall. Roman took care of everything with precision. The paint matched perfectly, and he left our space clean. Super happy with the service!",
    link: "https://www.yelp.com/biz/hubpro-furniture-assembly-irvine",
  },
  {
    platformIcon: Google_Reviews,
    avatar: Emma,
    name: "Emma Davis",
    date: "March 1, 2025",
    stars: "★★★★★",
    review:
      "We needed sheetrock repair in our garage. Roman arrived on time, did excellent work, and was very professional. Can’t thank him enough! Definitely recommend HubPro.",
    link: "https://g.page/r/CYsIhjJfreWDEB0/",
  },
  {
    platformIcon: Google_Reviews,
    avatar: Jason,
    name: "Jason M.",
    date: "March 8, 2025",
    stars: "★★★★★",
    review:
      "Roman was amazing! He repaired a hole in our drywall and repainted the wall. The whole process was stress-free, and the results were perfect. Great customer service!",
    link: "https://g.page/r/CYsIhjJfreWDEB0/",
  },
  {
    platformIcon: Yelp_Reviews,
    avatar: Lisa,
    name: "Lisa H.",
    date: "March 14, 2025",
    stars: "★★★★★",
    review:
      "We had Roman repair some sheetrock damage in our rental unit. He was professional, efficient, and the walls look flawless. Would definitely recommend him to others.",
    link: "https://www.yelp.com/biz/hubpro-furniture-assembly-irvine",
  },
  {
    platformIcon: Google_Reviews,
    avatar: Mark,
    name: "Mark T.",
    date: "March 20, 2025",
    stars: "★★★★★",
    review:
      "Roman helped us with drywall patching and painting in our kitchen. Excellent work! He was on time, polite, and super thorough. The wall looks perfect now.",
    link: "https://g.page/r/CYsIhjJfreWDEB0/",
  },
  {
    platformIcon: Yelp_Reviews,
    avatar: Laura,
    name: "Laura B.",
    date: "March 24, 2025",
    stars: "★★★★★",
    review:
      "Had some drywall damage from a door handle. Roman fixed it quickly and left no trace of the damage. He even matched the paint perfectly. Five stars for sure!",
    link: "https://www.yelp.com/biz/hubpro-furniture-assembly-irvine",
  },
];

function ReviewsDrywallRepair() {
  const city = "Orange County";

  const settings = {
    autoplay: true,
    autoplaySpeed: 2000,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <section className="ReviewsTV">
      <div>
        <h2 className="reviews-tv__header">
          Real{" "}
          <span style={{ color: "orange", fontWeight: "bold" }}>5-Star </span>
          <span style={{ display: "inline-block", verticalAlign: "middle" }}>
            <img
              src={YelpFavicon}
              alt="Yelp favicon"
              style={{
                width: "24px",
                height: "24px",
                marginLeft: "10px",
                marginRight: "3px",
              }}
            />
          </span>
          Yelp and
          <span style={{ display: "inline-block", verticalAlign: "middle" }}>
            <img
              src={GoogleFavicon}
              alt="Google favicon"
              style={{
                width: "24px",
                height: "24px",
                marginLeft: "10px",
                marginRight: "3px",
              }}
            />
          </span>
          Google Reviews About Us - your trusted choice for Drywall Repair
          Service from your Orange County neighbors!
        </h2>
        <Slider {...settings} className="reviews-tv__slider">
          {reviews.map((review, index) => (
            <div className="reviews-tv__review" key={index}>
              <img
                src={review.platformIcon}
                alt="Platform Icon"
                className="reviews-tv__icon"
              />
              <img
                src={review.avatar}
                alt={`${review.name} Avatar`}
                className="reviews-tv__avatar_2"
              />
              <div className="reviews-tv__author-wrapper">
                <a
                  className="reviews-tv__author"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={review.link}
                >
                  {review.name}
                </a>
                <div className="reviews-tv__date">{review.date}</div>
                <div className="reviews-tv__stars">{review.stars}</div>
              </div>
              <div className="reviews-tv__content">
                <p>{review.review}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
}

export default ReviewsDrywallRepair;
