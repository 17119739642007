import React, { useEffect } from "react";
import GetQuoteHome from "../quote/GetQuoteHome";
import Check from "../../assets/image/check.webp";
import ImageSlider from "../sliders/ImageSlider";

function Home() {
  const city = "Orange County";

  useEffect(() => {
    const handleAccordionClick = () => {
      const accordionItems = document.querySelectorAll(".accordion__item");

      accordionItems.forEach((item) => {
        const title = item.querySelector(".accordion__title");
        const content = item.querySelector(".accordion__content");

        title.addEventListener("click", function () {
          item.classList.toggle("active");
          content.classList.toggle("active");
          const plusIcon = title.querySelector(".plus-icon");
          plusIcon.style.transform = item.classList.contains("active")
            ? "rotate(45deg)"
            : "rotate(0deg)";
        });
      });
    };

    handleAccordionClick();

    return () => {
      const accordionItems = document.querySelectorAll(".accordion__item");

      accordionItems.forEach((item) => {
        const title = item.querySelector(".accordion__title");
        title.removeEventListener("click", handleAccordionClick);
      });
    };
  }, []);

  return (
    <main>
      <div className="mobile-container-home">
        <GetQuoteHome city={city} />
        <br></br>
      </div>

      <div className="mobile-container-home">
        <div class="slider-container">
          <h2 class="about__h1">
            Browsing Through Our Portfolio of Services in {city}
          </h2>
          <br></br>
          <br></br>
          <ImageSlider />
          <br></br>
          <br></br>
        </div>
      </div>

      <section class="about" id="about">
        <div className="mobile-container-home">
          <div class="about__container container">
            <h2 class="about__h1">
              Welcome to our hassle-free furniture assembly service in {city}!
            </h2>
            <div class="about__p">
              <p>
                Say goodbye to frustration and wasted time with our expert team
                on the job.
              </p>
              <h2 class="about__h1">Why choose us?</h2>
              <div class="feature-list">
                <div class="feature-item">
                  <div class="icon">
                    <img src={Check} alt="Expertise Icon" />
                  </div>
                  <div class="content-about">
                    <h3>Expertise</h3>
                    <p>
                      Our skilled technicians guarantee swift and precise
                      furniture assembly, saving you time and effort.
                    </p>
                  </div>
                </div>
                <div class="feature-item">
                  <div class="icon">
                    <img src={Check} alt="Expertise Icon" />
                  </div>
                  <div class="content-about">
                    <h3>Convenience</h3>
                    <p>
                      From start to finish, we handle everything, leaving you
                      with perfectly assembled furniture and no cleanup.
                    </p>
                  </div>
                </div>
                <div class="feature-item">
                  <div class="icon">
                    <img src={Check} alt="Expertise Icon" />
                  </div>
                  <div class="content-about">
                    <h3>Reliability</h3>
                    <p>
                      Punctual and dependable, we ensure your furniture is
                      assembled to your satisfaction, every time.
                    </p>
                  </div>
                </div>
                <div class="feature-item">
                  <div class="icon">
                    <img src={Check} alt="Expertise Icon" />
                  </div>
                  <div class="content-about">
                    <h3>Security</h3>
                    <p>
                      Our service is entirely secure, with all our employees
                      rigorously vetted over the years for their integrity and
                      reliability.
                    </p>
                  </div>
                </div>
              </div>
              <p>
                Don't let furniture assembly stress you out. Experience
                professional service that exceeds expectations.
              </p>
            </div>
          </div>
        </div>
      </section>
      <div class="testimony">
        <div class="testimony__container container">
          <p class="testimony__p">
            "Ready to upgrade your {city} space hassle-free? We're your seasoned
            pros for furniture assembly and TV mounting in {city}. With years of
            expertise and top-notch equipment, we'll transform your home into a
            haven. Get your free quote now and let's make your vision a
            reality!"
          </p>
        </div>
      </div>

      <div className="mobile-container-home">
        <div class="accordion">
          <div class="accordion__item">
            <div class="accordion__title">
              Where do we serve?
              <div class="plus-icon">+</div>
            </div>
            <div class="accordion__content">
              In the heart of {city}, we serve the entire area with a strong
              commitment to our local community.
            </div>
          </div>
          <div class="accordion__item">
            <div class="accordion__title">
              Our Operating Hours
              <div class="plus-icon">+</div>
            </div>
            <div class="accordion__content">
              Expert Team Available 8 AM - 8 PM Daily.
            </div>
          </div>
          <div class="accordion__item">
            <div class="accordion__title">
              Understanding Our Pricing Strategy
              <div class="plus-icon">+</div>
            </div>
            <div class="accordion__content">
              Each project is a unique masterpiece. Our quotes capture its
              essence with precision. Contact us to start your masterpiece –
              we’re flexible and ready to discuss terms that suit you.
            </div>
          </div>
          <div class="accordion__item">
            <div class="accordion__title">
              Who are our clients?
              <div class="plus-icon">+</div>
            </div>
            <div class="accordion__content">
              Serving both homes and businesses, we cater to a wide range of
              clients including homeowners, entrepreneurs, and property
              managers.
            </div>
          </div>
          <div class="accordion__item">
            <div class="accordion__title">
              Do I need to be present or can access arrangements be made?
              <div class="plus-icon">+</div>
            </div>
            <div class="accordion__content">
              Professional home services, completed efficiently—even when you're
              not home.
            </div>
          </div>
          <div class="accordion__item">
            <div class="accordion__title">
              Trusted Technicians in Your Home
              <div class="plus-icon">+</div>
            </div>
            <div class="accordion__content">
              Trust our skilled team with a decade of experience. Each
              technician undergoes rigorous Google Business vetting for your
              peace of mind. Equipped with top tools, they handle every job with
              professionalism and expertise, prioritizing safety protocols and
              customer confidentiality.
            </div>
          </div>
          <div class="accordion__item">
            <div class="accordion__title">
              Service Guarantees?
              <div class="plus-icon">+</div>
            </div>
            <div class="accordion__content">
              Championing American values of reliability and satisfaction, we
              guarantee 100% customer satisfaction.
            </div>
          </div>
        </div>
      </div>

      <div class="get-quote">
        <div class="get-quote__container container">
          <div class="get-quote__p">
            Transform Your Home with Expert Furniture Assembly & TV Mounting!
          </div>
          <a
            class="get-quote__link action-button"
            id="bottomSmsButtonHomePage"
            href="sms:+19494380343"
          >
            Text Us 24/7
          </a>
        </div>
      </div>
    </main>
  );
}

export default Home;
