import React, { useState } from "react";
import axios from "axios";

const GetQuotePainting = () => {
  const [formData, setFormData] = useState({
    service: "DrywallRepairService",
    phone: "",
    message: "",
  });

  const [showThankYouMessage, setShowThankYouMessage] = useState(false);
  const [phoneError, setPhoneError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "phone") {
      // Оставляем только цифры
      let phoneNumber = value.replace(/\D/g, "");

      // Ограничиваем количество цифр до 10
      if (phoneNumber.length > 10) {
        phoneNumber = phoneNumber.slice(0, 10);
      }

      // Форматируем номер телефона
      if (phoneNumber.length == 10) {
        phoneNumber = phoneNumber.replace(
          /^(\d{0,3})(\d{0,3})(\d{0,4})$/,
          (match, p1, p2, p3) => {
            let formatted = "";
            if (p1) formatted += `(${p1}`;
            if (p1.length === 3) formatted += ") ";
            if (p2) formatted += `${p2}`;
            if (p2.length === 3) formatted += "-";
            if (p3) formatted += `${p3}`;
            return formatted;
          },
        );
      }

      // Обновляем состояние формы
      setFormData({ ...formData, [name]: phoneNumber });

      // Проверка на количество цифр
      if (phoneNumber.length < 10) {
        setPhoneError("Phone number must contain 10 digits");
      } else {
        setPhoneError("");
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (phoneError) {
      return; // Предотвращаем отправку формы, если есть ошибки валидации
    }
    console.log(formData);
    axios
      .post("./contactForm.php", JSON.stringify(formData), {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response.data);
        setFormData({
          service: "",
          phone: "",
          message: "",
        });
        setShowThankYouMessage(true);
      })
      .catch((error) => {
        console.error("Error submitting form data:", error);
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <section className="banner-quote" id="quote">
        {!showThankYouMessage && (
          <div>
            <h3 className="banner-quote__h1">Get a Free Estimate Today!</h3>
            <div className="banner-form__item">
              <input
                className="banner-form__input banner-form__input_telephone"
                id="phone"
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="Phone"
                required
              />
              {phoneError && <span style={{ color: "red" }}>{phoneError}</span>}
            </div>
            <div className="banner-form__item">
              <textarea
                className="banner-form__input"
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="How Can We Help You?"
                required
                style={{ width: "100%", height: "100px", padding: "15px" }}
              ></textarea>
            </div>
            <div className="banner-form__controls">
              <button
                className="banner-form__submit action-button"
                type="submit"
              >
                <span
                  className="action-button__text"
                  id="sendMessageButtonTvPage"
                >
                  Free Estimate
                </span>
              </button>
            </div>
          </div>
        )}
        {showThankYouMessage && (
          <div
            id="thankYouMessage"
            style={{
              display: "block",
              color: "orange",
              fontSize: "22px",
              textTransform: "uppercase",
              padding: "20px",
              marginTop: "20px",
            }}
          >
            Thank you for your message! We will contact you shortly.
          </div>
        )}
      </section>
    </form>
  );
};

export default GetQuotePainting;
